import React from 'react';
import { graphql } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import { BackGround } from '../components/background';
import * as Styles from '../styles/pages/_index.module.scss';
import News_icon from '/static/news_icon.svg';
import Movie_icon from '/static/movie_icon.svg';
import Gallery_icon from '/static/gallery_icon.svg';
import Twitter from '/static/twitter.svg';
import Line from '/static/line.svg';
import Youtube from '/static/youtube.svg';
import Facebook from '/static/facebook.svg';
import Instagram from '/static/insta.svg';
import { dayjs } from '../lib/dayjs';
import { useLightBox } from '../hooks/use-light-box';
import { useWindowSize } from '../hooks/use-window-size';
import { isNewsTypeColor } from '../lib/is-news-type-color';
import {Link, useIntl} from "gatsby-plugin-react-intl"
import { contentPathHelper } from '../utils/contentPathHelper'


const IndexPage = ({ data, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const [galleryIndex, setGalleryIndex] = React.useState(null);
  const { LightBox, open } = useLightBox('gallery', setGalleryIndex);

  const sliderBg = React.useRef(null);

  const images = React.useMemo(() => {
    return data.allMicrocmsIllust.edges.map(({ node }) => {
      return {
        image: `${node.image.url}?fm=webp`,
        title: node.title,
        artist: node.artist
      }
    });
  }, [data.allMicrocmsIllust.edges]);

  const setOnSwiper = (swiper) => {
    const image = swiper.slides[swiper.activeIndex].querySelector('img');
    const imageSrc = image.getAttribute('src').replace('?w=659&q=80', '');

    setTimeout(() => (sliderBg.current.style.backgroundImage = `url(${imageSrc})`));
  };

  const setOnSlideChange = (swiper) => {
    const image = swiper.slides[swiper.activeIndex].querySelector('img');
    const imageSrc = image.getAttribute('src').replace('?w=659&q=80', '');

    setTimeout(() => {
      sliderBg.current.classList.add(Styles.animationStart);

      sliderBg.current.addEventListener('transitionend', () => {
        sliderBg.current.style.backgroundImage = `url(${imageSrc})`;
        setTimeout(() => {
          sliderBg.current.classList.remove(Styles.animationStart);
        });
      });
    });
  };

  // windowサイズを取得するhook
  const windowSize = useWindowSize();
  // ブレークポイントでSwiperの表示数を変更
  const swiperState = React.useMemo(() => {
    if (windowSize === null) {
      return {
        spaceBetween: 0,
        slidesPerView: 2.1,
      };
    }

    if (windowSize > 650) {
      return {
        spaceBetween: 0,
        slidesPerView: 2.1,
      };
    } else {
      return {
        spaceBetween: 11,
        slidesPerView: 1.16,
      };
    }
  }, [windowSize]);

  return (
    <Layout location={location}>
      <Seo title={t('home.seo.title')} description={t('home.seo.description')} />
      <section id="top" className={`top ${Styles.top}`}>
        <div className={`top_slider ${Styles.top_slider}`}>
          <div className={Styles.top_slider__bg} ref={sliderBg}></div>
          {data.allMicrocmsIllust.edges.length > 0 && (
            <Swiper spaceBetween={swiperState.spaceBetween} slidesPerView={swiperState.slidesPerView} navigation autoplay={{ delay: 7000 }} centeredSlides={true} pagination={{ clickable: true }} onSlideChange={(swiper) => setOnSlideChange(swiper)} onSwiper={(swiper) => setOnSwiper(swiper)} loop modules={[Autoplay, Navigation, Pagination]}>
              {data.allMicrocmsBanner.edges.map(({ node }) => (
                <SwiperSlide key={node.bannerId} className={Styles.top__slider__item}>
                  <Link to={node.url}>
                    <img src={node.image.url} ref={node.bannerId} />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          <div className={Styles.top_slider__border}></div>
        </div>
      </section>
      <BackGround>
        <div className={Styles.top_background}>
          <div className={Styles.top_wrap}>
            <div className={Styles.top_newswrap}>
              <section id="news">
                <div className={Styles.top_title}>
                  <img src={News_icon} className="news_icon" alt="news_icon" />
                  <h2>{t('home.newArrivals')}</h2>
                  <div className={Styles.top_title__border}></div>
                </div>
                {data.allMicrocmsNews.edges.length > 0 ? (
                  data.allMicrocmsNews.edges.map(({ node }) => (
                    <Link key={node.newsId} to={`/news/${contentPathHelper(node.newsId)}`} className={Styles.top_news__newsbox}>
                      <div className={Styles.top_news__set}>
                        {dayjs(node.publishedAt).format('YYYY/MM/DD')}
                        <p className={Styles.top_news__category} style={{ backgroundColor: isNewsTypeColor(node.type[0]) }}>
                          {node.type[0]}
                        </p>
                      </div>
                      <p>{node.title}</p>
                    </Link>
                  ))
                ) : (
                  <div className={Styles.top_news__newsbox}>
                    <p>{t('home.newsNotPosted')}。</p>
                  </div>
                )}
              </section>
              <section>
                <Link id="vote-panel" class={Styles.vote_panel} to="">
                  <div className={Styles.top_vote}></div>
                </Link>
                <div className={Styles.top_youtube}>
                  <iframe width="400" height="253" src="https://www.youtube.com/embed/nd23iBq0W5A" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                </div>
                <div className={Styles.top_twitter}>
                  <a className="twitter-timeline" data-width="409" data-height="260" data-theme="dark" href="https://twitter.com/LIMITS_DABA?ref_src=twsrc%5Etfw">
                    {' '}
                  </a>
                  <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
                </div>
                <div className={Styles.top_sns}>
                  <a href="http://twitter.com/LIMITS_DABA" target="_blank">
                    <img src={Twitter} className="twitter" alt="twitter" />
                  </a>
                  <a href="https://line.me/R/ti/p/%40limits" target="_blank">
                    <img src={Line} className="line" alt="line" />
                  </a>
                  <a href="https://www.youtube.com/c/limits_ch" target="_blank">
                    <img src={Youtube} className="youtube" alt="youtube" />
                  </a>
                  <a href="https://www.facebook.com/limits.daba/" target="_blank">
                    <img src={Facebook} className="facebook" alt="facebook" />
                  </a>
                  <a href="https://www.instagram.com/limits_artbattle/" target="_blank">
                    <img src={Instagram} className="instagram" alt="instagram" />
                  </a>
                </div>
              </section>
            </div>

            <section className={Styles.top_video}>
              <div className={Styles.top_title}>
                <img src={Movie_icon} className="movie_icon" alt="movie_icon" />
                <h2>{t('home.limitsChannel')}</h2>
                <div className={Styles.top_title__border2}></div>
              </div>
              <div className={Styles.top_video__wrap}>
              {data.allMicrocmsChannel.edges.length > 0 ? (
                  data.allMicrocmsChannel.edges.map(({ node }, index) => (
                    <a href={`https://www.youtube.com/watch?v=${node.video_id}`} className={Styles.top_video__item} key={node.channelId} target="_blank">
                      <img src={`https://img.youtube.com/vi/${node.video_id}/maxresdefault.jpg`} alt={node.title} />
                      <p>{node.title}</p>
                    </a>
                  ))
                ) : (
                  <p>{t('home.noMoviePosted')}。</p>
                )}
              </div>
              <p className={Styles.more_btn}>
                <a href='https://www.youtube.com/c/limits_ch' target="_blank" >{t('home.more')}</a>
              </p>
            </section>

            <section>
              <div className={Styles.top_title}>
                <img src={Gallery_icon} className="gallery_icon" alt="gallery_icon" />
                <h2>{t('home.gallery')}</h2>
                <div className={Styles.top_title__border3}></div>
              </div>
              <div className={Styles.top_gallerywrap}>
              {data.allMicrocmsIllust.edges.length > 0 ? (
                  data.allMicrocmsIllust.edges.map(({ node }, index) => (
                    <p key={node.illustId}>
                      <button
                        type="button"
                        onClick={() => {
                          setGalleryIndex(index);
                          open();
                        }}
                      >
                        <img src={node.image.url + '?fit=crop&w=300&h=300&fm=webp'} alt="" />
                      </button>
                    </p>
                  ))
                ) : (
                  <p>{t('home.noIllustrationsPosted')}。</p>
                )}
              </div>
              <LightBox
                images={images}
                currentIndex={galleryIndex}
                onMovePrev={() => setGalleryIndex((galleryIndex + images.length - 1) % images.length)}
                onMoveNext={() => setGalleryIndex((galleryIndex + 1) % images.length)}
                navigation
                caption
              />
            </section>
          </div>
          <p className={Styles.more_btn_bg}>
            <Link to="/gallery/">{t('home.more')}</Link>
          </p>
        </div>
      </BackGround>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMicrocmsBanner {
      edges {
        node {
          image {
            url
          }
          url
        }
      }
    }
    allMicrocmsNews(limit: 7, sort: { fields: [publishedAt], order: DESC }, filter: {is_private: {eq: false}}) {
      edges {
        node {
          newsId
          title
          type
          is_private
          publishedAt
        }
      }
    }

    allMicrocmsChannel(limit: 8, sort: { fields: [publishedAt], order: DESC }, filter: {is_private: {eq: false}}) {
      edges {
        node {
          channelId
          title
          video_id
        }
      }
    }

    allMicrocmsEvent(filter: {is_private: {eq: false}}) {
      edges {
        node {
          eventId
          date
          title
        }
      }
    }
    allMicrocmsIllust(limit: 6, sort: { fields: [publishedAt], order: DESC }, filter: {is_private: {eq: false}}) {
      edges {
        node {
          illustId
          title
          artist {
            name
            id
            image {
              url
            }
          }
          image {
            url
          }
        }
      }
    }
  }
`;

export default IndexPage;
