// extracted by mini-css-extract-plugin
export var top_background = "_index-module--top_background--NryDT";
export var top_wrap = "_index-module--top_wrap--Y3Ers";
export var top = "_index-module--top--DImfJ";
export var top_slider = "_index-module--top_slider--W68aY";
export var top_slider__bg = "_index-module--top_slider__bg--T0hxN";
export var animationStart = "_index-module--animationStart--yabuV";
export var top_slider__border = "_index-module--top_slider__border--CroGK";
export var top_title = "_index-module--top_title--xLgRW";
export var top_title__border = "_index-module--top_title__border--tAi-B";
export var top_title__border2 = "_index-module--top_title__border2--A4uVQ";
export var top_title__border3 = "_index-module--top_title__border3--ZXMyT";
export var top_newswrap = "_index-module--top_newswrap--FW2Gl";
export var top_news__newsbox = "_index-module--top_news__newsbox--n6buw";
export var top_news__set = "_index-module--top_news__set--HSrfI";
export var top_news__category = "_index-module--top_news__category--1j8tn";
export var vote_panel = "_index-module--vote_panel--BR+AC";
export var top_vote = "_index-module--top_vote--1F9sz";
export var top_youtube = "_index-module--top_youtube--K227I";
export var top_twitter = "_index-module--top_twitter--Bmqx1";
export var top_sns = "_index-module--top_sns--Pxrb1";
export var more_btn = "_index-module--more_btn--X3a5b";
export var more_btn_bg = "_index-module--more_btn_bg--7Dhjr";
export var top_gallerywrap = "_index-module--top_gallerywrap--mLs91";
export var top_video__wrap = "_index-module--top_video__wrap--KirYX";
export var top_video__item = "_index-module--top_video__item--nRXwb";